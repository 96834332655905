



































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import StepCounter from '@/components/step-counter/index.vue'
import HorakiEngine from '@/components/horaki-engine/index.vue'
import { binderModule, brokerageModule, cobModule, underwritersModule } from '@/store/store-accessor'
import { validateForm } from '@/helpers/validations'
import { Question, AnswerMap } from '@/models/questionnaire'
import ApplicationMixin from '@/mixins/application-mixin'
import ApplicationLayout from '@/layouts/application-layout.vue'
import LoadingIcon from '@/components/loading-icon/index.vue'
import Spacer from '@/components/spacer/index.vue'
import LoadableMixin from '@/mixins/loadable-mixin'
import { Section } from '@/models/layout'
import { ROUTES } from '@/router/routes'
import SaveForLaterForm from '@/components/save-for-later/index.vue'
import Drawer from '@/components/drawer/index.vue'
import * as FullStory from '@fullstory/browser'
import BrokerageMixin from '@/mixins/brokerage-mixin'
import AppetiteCallout from '@/components/appetite-callout/index.vue'
import DrawerSaveForLater from '@/components/save-for-later/drawer/index.vue'
import IconBase from '@/components/icon-library/icon-base.vue'
import Warning from '@/components/icon-library/warning.vue'
import ApplicationPrep from '@/views/ApplicationPrep.vue'
import NoAppetite from '@/views/NoAppetite.vue'
import Dialog from '@/components/dialog/index.vue'
import { generateCompanyKey } from '@/helpers/utils'

@Component({
  name: 'PreApplication',
  components: {
    NoAppetite,
    AppetiteCallout,
    ApplicationPrep,
    DrawerSaveForLater,
    ApplicationLayout,
    StepCounter,
    HorakiEngine,
    LoadingIcon,
    Spacer,
    SaveForLaterForm,
    Drawer,
    IconBase,
    Warning,
    Dialog
  }
})
export default class PreApplication extends Mixins(ApplicationMixin, LoadableMixin, BrokerageMixin) {
  // Shows dialog to resume existing application or continue as a new
  showDialog = false

  // Question list for the pre-app
  get questions (): Question[] {
    if (!binderModule.preApplication) {
      return [] // Still loading
    }
    return binderModule.questions
  }

  // Layout node for the UI
  get layout (): Section[] {
    if (!binderModule.preApplication) {
      return [] // Still loading
    }
    return binderModule.layout
  }

  get preApplicationComplete () {
    return binderModule.preApplicationComplete
  }

  get preApplicationNoAppetite () {
    return binderModule.preApplicationNoAppetite
  }

  get showSaveForLater (): boolean {
    return binderModule.toggleSaveForLater
  }

  get lastQuestionAnswered () {
    return binderModule.answers.Location1ClassOfBusiness
  }

  toggleSaveForLater (): void {
    binderModule.setSaveForLater()
  }

  @Watch('answers')
  async onAnswersChanged () {
    if (!this.validateCompany(this.answers)) {
      const key = generateCompanyKey(this.answers)
      const dialog = sessionStorage.getItem(`${key}_dialog`)
      if (!dialog) {
        this.showDialog = true
        sessionStorage.setItem(`${key}_dialog`, '1')
      }
    }

    if (this.answersValid()) {
      this.showBtnLoading()

      try {
        await binderModule.saveAnswers()
      } catch (e: any) {
        this.$logger.warn(`Failed to save pre application answers ${e.message}`)
      } finally {
        this.hideBtnLoading()
      }
    }
  }

  answersValid (): boolean {
    if (!this.$refs.application) {
      return false
    }
    const validationMessages = validateForm(
      this.$refs.application as HTMLFormElement,
      this.questions,
      this.answers
    )
    return validationMessages.length === 0
  }

  get underwritersInPotentialAppetite (): string[] {
    return [...new Set(Object.keys(binderModule.preApplication?.potentialAppetite ?? {}))]
  }

  async created () {
    this.showLoading()

    try {
      await Promise.all([
        binderModule.checkPreApplication()
      ])
      await underwritersModule.getUnderwriters()
    } catch (err: any) {
      this.$logger.warn(`Unsuccesful load of pre-application: ${err.message}`)
    }

    if (!binderModule.preApplication) {
      return this.$router.push({
        name: ROUTES.PAGE_NOT_FOUND
      })
    }

    if (this.preApplicationComplete) {
      return this.$router.push({
        name: ROUTES.APPLICATION,
        params: { id: this.id }
      })
    }

    await binderModule.setQuestionnaire(binderModule.preApplication.questionnaire)

    document.title = brokerageModule.brokerage
      ? `${brokerageModule.brokerage.name} | ${this.$route?.meta?.title}`
      : this.$route?.meta?.title

    this.hideLoading()
  }

  // Continue button click to move to next section in the pre-app
  async moveNext () {
    this.showBtnLoading()

    const validationMessages = validateForm(
      this.$refs.application as HTMLFormElement,
      this.questions,
      this.answers
    )

    binderModule.updateFormValidation(validationMessages)
    if (validationMessages.length > 0) {
      this.hideBtnLoading()
      return this.focusField(validationMessages[0].field)
    }

    await binderModule.saveAnswers()

    if (this.answers.BusinessName && this.answers.LinkEmail) {
      this.setFullStoryUserVars(
        this.answers.BusinessName as string,
        this.answers.LinkEmail as string
      )
    }

    if (!this.applicationComplete) {
      this.hideBtnLoading()
      return this.showNextSection()
    }

    await binderModule.convertToApplication()
  }

  validateCompany (answers: AnswerMap) {
    const { BusinessName, LinkEmail } = answers
    if (!BusinessName || !LinkEmail) return true
    const key = generateCompanyKey(answers)
    const company = localStorage.getItem(`${key}_token`)
    return !company
  }

  private setFullStoryUserVars (displayName: string, email: string) {
    FullStory.setUserVars({
      displayName,
      email
    })
  }

  async navigateToApplication () {
    if (binderModule.application) {
      return this.$router.push({
        name: ROUTES.APPLICATION,
        params: { id: binderModule.application.id }
      })
    }

    throw new Error('Unsuccessful load of application')
  }

  dialogConfirm () {
    this.showDialog = false
    const key = generateCompanyKey(this.answers)
    const token = localStorage.getItem(`${key}_token`) || ''
    window.sessionStorage.setItem('briza-application-token', token)
    const id = localStorage.getItem(`${key}_id`) || ''
    const route = localStorage.getItem(`${key}_route`) || ''
    this.$router.push({
      name: route,
      params: { id }
    })
  }
}
