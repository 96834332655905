



























































































import { Component, Emit, Mixins } from 'vue-property-decorator'
import LoadableMixin from '@/mixins/loadable-mixin'
import LoadingIcon from '@/components/loading-icon/index.vue'
import ApplicationMixin from '@/mixins/application-mixin'

@Component({
  name: 'ApplicationPrep',
  components: {
    LoadingIcon
  }
})
export default class ApplicationPrep extends Mixins(ApplicationMixin, LoadableMixin) {
  loading = false

  @Emit('goToApplication')
  beginApplication () {
    this.showBtnLoading()
  }
}
