







































import CalloutBox from '@/components/callout-box/index.vue'
import UnderwriterLogo from '@/components/quote/underwriter-logo.vue'
import { underwritersModule } from '@/store/store-accessor'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'AppetiteCallout',
  components: {
    CalloutBox,
    UnderwriterLogo
  },
  props: {
    underwriters: {
      type: Array as PropType<string[]>,
      default: () => { return [] }
    }
  },
  setup (props) {
    const showAll = ref<boolean>(false)
    const showCallout = computed(() => {
      return props.underwriters.length > 0
    })
    const underwritersToShow = computed(() => {
      return showAll.value ? props.underwriters : props.underwriters.slice(0, 8)
    })
    const getUnderwriter = (underwriterSlug: string) => {
      return underwritersModule.underwriterBySlug(underwriterSlug)
    }

    const seeMoreClick = () => {
      showAll.value = true
    }
    const showSeeMore = computed(() => {
      return !showAll.value && props.underwriters.length > 8
    })

    return {
      showAll,
      showCallout,
      underwritersToShow,
      getUnderwriter,
      seeMoreClick,
      showSeeMore
    }
  }
})
