




































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Dialog',
  components: {},
  props: {
    disableOverlay: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    confirm: {
      type: String,
      default: 'Confirm'
    },
    cancel: {
      type: String,
      default: 'Cancel'
    }
  }
})
