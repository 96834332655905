









































import { Component, Mixins } from 'vue-property-decorator'
import BrokerageMixin from '@/mixins/brokerage-mixin'

@Component({
  name: 'NoAppetite'
})
export default class NoAppetite extends Mixins(BrokerageMixin) {

}
